import React, { useState, useEffect } from 'react';

const DeviceCheck = ({ children }: { children: React.ReactNode }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768 || /Mobi|Android/i.test(navigator.userAgent));
    };

    checkIfMobile(); // Run on component mount
    window.addEventListener('resize', checkIfMobile); // Run on window resize

    return () => {
      window.removeEventListener('resize', checkIfMobile); // Cleanup event listener on unmount
    };
  }, []);

  if (isMobile) {
    return (
      <div style={{ textAlign: 'center', padding: '20px', color: 'white' }}>
        <h1>Mobile Access Not Supported</h1>
        <p>This site is only accessible on laptops or desktops. Please visit the site on your PC/laptop.</p>
      </div>
    );
  }

  return <>{children}</>;
};

export default DeviceCheck;
