import { useEffect } from "react"
import AnimationCard from "../Animation/Animation";
import Custom from "../Custom/Custom";
import {URLAnimation, Animations, BaseAnimation} from "../../url-animations";

import github from "../../assets/github-mark.png"
import coffee from "../../assets/bmc-logo.png"
import npm from "../../assets/npm-logo.png"
import bugs from '../../assets/bugs.png'

import dex from '../../assets/iconDex.webp';
import telegram from '../../assets/iconTg.webp';
import twitter from '../../assets/iconX.webp';
import pumpfun from '../../assets/pflogo.png';

import "./Home.css"

const CA = "FW4v3FZqY33RvC51guNLTkktWvUvF2HFQugf1mdQpump";
const telegramUrl = "";
const twitterUrl = "";
const website = "";
const pumpfunUrl = `https://pump.fun/${CA}`;
const dexUrl = `https://dexscreener.com/solana/${CA}`;

const Home = () => {
  const URL = URLAnimation();
  useEffect(() => {
    URL.start(Animations.default);
  }, [])

  const changeAnimation = (animation: BaseAnimation) => {
    URL.start(animation);
  };

  return (
    <div className="home-div">
      <div className="home-top">
        <h1>LOOK ABOVE --^</h1>
        <div className="homeTitle">
          ██╗░░░██╗██████╗░██╗░░░░░  ░█████╗░█████╗░████████╗
          <br></br>
          ██║░░░██║██╔══██╗██║░░░░░  ██╔══██╗██╔══██╗╚══██╔══╝
          <br></br>
          ██║░░░██║██████╔╝██║░░░░░  ██║░░╚═╝███████║░░░██║░░░
          <br></br>
          ██║░░░██║██╔══██╗██║░░░░░  ██║░░██╗██╔══██║░░░██║░░░
          <br></br>
          ╚██████╔╝██║░░██║███████╗  ╚█████╔╝██║░░██║░░░██║░░░
          <br></br>
          ░╚═════╝░╚═╝░░╚═╝╚══════╝  ░╚════╝░╚═╝░░╚═╝░░░╚═╝░░░
          <br></br>
        </div>
        <div className="homeLinks">
          <span className="logo-div">
            <a href={pumpfunUrl} target="_blank" rel="noreferrer">
              <h4>pumpfun </h4>
              <img className="home-logo" src={pumpfun} alt="pumpfun"></img>
            </a>
          </span>
          {/* <span className="logo-div">
            <a href={twitterUrl} target="_blank" rel="noreferrer">
              <h4>twitter </h4>
              <img className="home-logo-npm home-logo" src={twitter} alt="twitter"></img>
            </a>
          </span>
          <span className="logo-div">
            <a href={telegramUrl} target="_blank" rel="noreferrer">
              <h4>telegram </h4>
              <img className="home-logo" src={telegram} alt="telegram"></img>
            </a>
          </span> */}
          <span className="logo-div">
            <a href={dexUrl} target="_blank" rel="noreferrer">
              <h4>dex </h4>
              <img className="home-logo" src={dex} alt="dex"></img>
            </a>
          </span>
        </div>
      </div>
      <h2 className="contract-address">CA: {CA}</h2>
      <div className="instructions">
        <h3>How to create your custom animation:</h3>
        <p>1. Click on the "custom" button below</p>
        <p>2. Enter the series of animations you want to use, each of them in their own line</p>
        <p>3. You can adjust the animation speed using the slider below</p>
        <p>4. Now sit back and enjoy your url animation!</p>
      </div>
      <Custom URL={URL} />
      <div className="animation-div">
        {Object.keys(Animations).map((item, i) => {
          return Animations[item].name !== 'Custom' ? (
            <AnimationCard animation={Animations[item]} changeAnimation={changeAnimation} key={i} />
          ) : null;
        })}
      </div>
    </div>
  );
}

export default Home;