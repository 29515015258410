import React from 'react';
import Home from "./components/Home/Home";
import DeviceCheck from "./components/DeviceCheck";
import './App.css';

function App() {
  return (
    <React.StrictMode>
      <DeviceCheck>
        <Home />
      </DeviceCheck>
    </React.StrictMode>
  );
}

export default App;
